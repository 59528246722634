import React from 'react';
import styled from 'styled-components';
import {
  DatabaseOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { useDevice } from 'context/DeviceContext';
import { IsOnlineDevice } from 'utils';

const DeviceStatus = () => {
  const { devices, deviceByLocation } = useDevice();
  const totalDevices = deviceByLocation
    ? deviceByLocation.length
    : devices?.length || 0;
  const onlineDevices = deviceByLocation
    ? deviceByLocation?.filter((dv) => IsOnlineDevice(dv))
    : devices.filter((dv) => IsOnlineDevice(dv));
  const offlineDevices = totalDevices - onlineDevices?.length;

  const statusList = [
    {
      title: 'Thiết bị',
      number: totalDevices,
      icon: <DatabaseOutlined />,
      color: '#2AACF0',
    },
    {
      title: 'Thiết bị online',
      number: onlineDevices?.length || 0,
      icon: <EyeOutlined />,
      color: '#57B657',
    },
    {
      title: 'Thiết bị offline',
      number: offlineDevices,
      icon: <EyeInvisibleOutlined />,
      color: '#FFC100',
    },
  ];

  return (
    <Container>
      {statusList.map((item, idx) => (
        <Status key={idx} color={item.color}>
          <div>
            <Number>{item.number}</Number>
            <Title>{item.title}</Title>
          </div>
        </Status>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  transform: scale(0.9);
`;

const Status = styled.div<{ color: string }>`
  display: flex;
  position: relative;
  border-right: solid 5px ${({ color }) => color};
  padding: 0 16px 0 16px;
  text-align: right;
`;
const Title = styled.div`
  color: #606060;
  font-size: 11px;
  white-space: nowrap;
`;
const Number = styled.div`
  font-size: 28px;
  line-height: 28px;
  font-weight: 400;
`;

export default DeviceStatus;
