import { getNotifications } from '@api';
import { useQuery } from 'react-query';

export function useNotity() {
  const { data, isLoading, isError } = useQuery(
    'getNotifications',
    getNotifications,
    {
      refetchOnWindowFocus: false,
    }
  );
  return { data: data?.data, isLoading, isError };
}
