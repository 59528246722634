import { axiosInstance } from './axios';
import { AxiosPromise } from 'axios';
import { LoginFormValues, User } from '../@types';

export function login(
  params: LoginFormValues
): AxiosPromise<{ sessionKey: string; user: User }> {
  return axiosInstance.post('/user/login', params);
}

export function LoginRsa({
  params,
}: {
  params: LoginFormValues;
}): AxiosPromise<{ sessionKey: string; user: User; signature: string }> {
  return axiosInstance.post('/user/login-rsa', params);
}

export function getUserInfo(): AxiosPromise<User> {
  return axiosInstance.get('/user/getinfo');
}

export function logout(token: string) {
  return axiosInstance.post('/user/logout', { token });
}

export function getUsers(): AxiosPromise<User[]> {
  return axiosInstance.get('/user/getuser');
}

export function updateUserInfo(user: User) {
  return axiosInstance.post('/user/updateAES', user);
}

export function updateUserInfoWithAES({
  userAES,
  sessionKey,
}: {
  userAES: string;
  sessionKey: string;
}) {
  return axiosInstance.post('/user/updateAES', { userAES, sessionKey });
}
