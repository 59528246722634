import { Notify } from '@types';
import { AxiosPromise } from 'axios';
import { axiosInstance } from './axios';

export function setToken(token: string) {
  return axiosInstance.post('/register/notifications', { token });
}

export function getNotifications(): AxiosPromise<Notify[]> {
  return axiosInstance.get('/user/notifications');
}
