import axios from 'axios';

export const getCompanyInfo = () => {
  return axios.get('config.json', {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};
