import React from 'react';
import { useAuth } from '../context/AuthContext';
import AuthenticatedPage from './AuthenticatedPage';
import UnauthenticatedPage from './UnauthenticatedPage';

const Home = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <AuthenticatedPage /> : <UnauthenticatedPage />;
};

export default Home;
