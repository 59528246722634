import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Layout as AntLayout, Menu } from 'antd';
import { ReactComponent as Logo } from '../images/logo.svg';
import {
  DesktopOutlined,
  DatabaseOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import UserControl from './UserControl';
import { Link, useLocation } from 'react-router-dom';
import DeviceStatus from './DeviceStatus';
import TopNotification from './Notification';
import { Permission } from '@types';
import useUser from 'hooks/useUser';

const { Content } = AntLayout;

type Props = {
  children: React.ReactNode;
};
const Layout: FC<Props> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const { userInfo } = useUser();

  const navigation = [
    {
      label: 'Trang chủ',
      to: '/',
      icon: <DesktopOutlined />,
    },
    {
      label: 'Quản lý nội dung',
      to: '/noi-dung',
      icon: <DatabaseOutlined />,
    },
    {
      label: 'Quản lý thiết bị',
      to: '/thiet-bi',
      icon: <DatabaseOutlined />,
    },
    {
      label: 'Quản Lý Nhóm',
      to: '/nhom',
      icon: <TeamOutlined />,
      permission: [Permission.MANAGER_GROUP],
    },
    {
      label: 'Quản Lý Người Dùng',
      to: '/nguoi-dung',
      icon: <UserOutlined />,
      permission: [Permission.EDIT_USER],
    },
    // {
    //   label: 'Ghi Âm',
    //   to: '/ghi-am',
    //   // icon: <AudioOutlined />,
    // },
    // {
    //   label: 'Chuyển Đổi Âm Thanh',
    //   to: '/chuyen-doi-am-thanh',
    //   // icon: <BellOutlined />,
    //   // permission: [Permission.TTS_PERMISSION],
    // },
    // // {
    //   // label: 'Báo Cáo',
    //   // icon: <ProjectOutlined />,
    //   // permission: [
    //   //   Permission.REPORT_PERMISSION,
    //   //   Permission.TECHNICIAL_USER,
    //   //   Permission.ADMIN,
    //   // ],
    //   // subMenu: [
    //     {
    //       label: 'Báo cáo phát thanh',
    //       to: '/bao-cao-phat-thanh',
    //     },
    //     {
    //       label: 'Báo cáo reset',
    //       to: '/bao-cao-reset',
    //     },
    // ],
    // },
  ];

  const contentSelected = [
    '/noi-dung',
    '/noi-dung/cho-phat',
    '/noi-dung/da-phat',
    '/noi-dung/cho-phe-duyet',
    '/noi-dung/lich-lap-lai',
  ].includes(location.pathname);

  return (
    <AntLayout>
      <AntLayout className="site-layout">
        <StyledHeader>
          <StyledLogo className="logo">
            <div>
              <Logo width={32} />
            </div>
            <span
              className={`logo-name ${collapsed ? 'close-text' : 'open-text'}`}
            >
              RadioTech
            </span>
          </StyledLogo>

          <div style={{ marginLeft: 8, marginRight: 8 }}>
            {React.createElement(
              collapsed ? CaretRightOutlined : CaretLeftOutlined,
              {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
                style: { color: '#dedede' },
              }
            )}
          </div>

          <Menu
            theme="light"
            mode="horizontal"
            style={{ flex: 1, borderBottom: 'none' }}
            defaultSelectedKeys={[location.pathname]}
            selectedKeys={[
              location.pathname,
              contentSelected ? '/noi-dung' : '',
            ]}
          >
            {navigation.map((item) => {
              if (
                !item?.permission ||
                item?.permission.find((item) =>
                  userInfo?.permissions?.includes(item)
                )
              )
                return (
                  <Menu.Item
                    key={item.to}
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                  >
                    <Link to={item.to}>{item.label}</Link>
                  </Menu.Item>
                );

              return null;
            })}
          </Menu>
          <StyledHeader>
            <DeviceStatus />
            <TopNotification />
            <UserControl />
          </StyledHeader>
        </StyledHeader>
        <Content
          className="site-layout-background"
          style={{
            height: 'calc(100vh - 60px)',
          }}
        >
          {children}
        </Content>
      </AntLayout>
    </AntLayout>
  );
};

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 60px;
  padding: 0 10px;
`;

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  .open-text {
    display: block;
  }
  .close-text {
    display: none;
  }
  .logo-name {
    margin-left: 10px;
    padding-top: 5px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
  }
`;

const CustommerInfo = styled.div`
  display: none;
  padding: 0 0 0 16px;
  @media (min-width: 1024px) {
    display: block;
  }
`;

export default Layout;
