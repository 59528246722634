import React, { FC } from 'react';
import { ReactComponent as LogoSVG } from '../images/logo3.svg';
import styled from 'styled-components';

type Props = {
  hideText?: boolean;
  width?: number;
  height?: number;
  text?: string;
};

const Logo: FC<Props> = ({ hideText, width, height, text }) => {
  return (
    <StyledLogo>
      <div>
        <LogoSVG width={width} height={height} />
      </div>
      {!hideText && <Text>{text}</Text>}
    </StyledLogo>
  );
};

const StyledLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Text = styled.div`
  font-size: 24px;
  font-weight: 700;
  padding-top: 20px;
  text-transform: uppercase;
  margin-left: 10px;
`;

export default Logo;
