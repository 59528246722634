import { Notify } from '@types';
import { useEffect, useState } from 'react';
import { messaging } from '../firebase';
import { checkSupportedNotifyBrowser } from '../firebase/util';

const useMessaging = () => {
  const [notify, setNotify] = useState<Notify>();

  useEffect(() => {
    checkSupportedNotifyBrowser();
  }, []);

  useEffect(() => {
    if (messaging) {
      messaging.onMessage((payload: Notify) => {
        if (payload) {
          setNotify(payload);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return notify;
};

export default useMessaging;
