export enum DEVICE_STATUS {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  WAITING = 'WAITING',
  LOST_CONNECT = 'LOST_CONNECT',
}

export const DeviceStatusName = {
  [DEVICE_STATUS.ONLINE]: 'Đang Phát',
  [DEVICE_STATUS.OFFLINE]: 'Tạm Dừng',
  [DEVICE_STATUS.WAITING]: 'Chờ Phát',
  [DEVICE_STATUS.LOST_CONNECT]: 'Mất kết nối',
};

export enum STATUS_COLOR {
  OFF_COLOR = '#FF0202',
  ON_COLOR = '#2AACF0',
  WAIT_COLOR = '#57B657',
  LOST_CONNECT = '#FF0202',
}

export enum CONTROL_MODE {
  MODE_1 = 'MODE(1)', // Phát từ internet (streaming)
  MODE_2 = 'MODE(2)', // Phát từ bộ thu FM
  MODE_3 = 'MODE(3)', // Phát từ MIC
  MODE_4 = 'MODE(4)', // Không hoạt động
}
