const CryptoJS = require('crypto-js');
const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ECRYPT_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ECRYPT_IV);

export function encrypt(plaintext: string): string {
  return CryptoJS.AES.encrypt(plaintext, key, {
    iv,
  }).toString();
}

export function decrypt(encryptedText: string): string {
  return CryptoJS.AES.decrypt(encryptedText, key, {
    iv,
  }).toString(CryptoJS.enc.Utf8);
}
