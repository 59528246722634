export const VoiceType = {
  LM_B: 'Lê Minh (Nam Miền Bắc)',
  BM_B: 'Ban Mai (Nữ Miền Bắc)',
  TM_B: 'Thu Minh (Nữ Miền Bắc)',
  GH_T: 'Gia Huy (Nam Miền Trung)',
  NN_T: 'Ngọc Nam (Nữ Miền Trung)',
  MA_T: 'Mỹ An (Nữ Miền Trung)',
  LN_N: 'Lan Nhi (Nữ Miền Nam)',
  LS_N: 'Linh San (Nữ Miền Nam)',
  MQ_N: 'Minh Quang (Nam Miền Nam)',
};

export enum VoiceSpeed {
  BT = 0,
  RN = 2,
  N = 1,
  C = -1,
  RC = -2,
}

export const VoiceSpeedType = {
  [`${VoiceSpeed.BT}`]: 'Bình thường',
  [`${VoiceSpeed.RN}`]: 'Rất nhanh',
  [`${VoiceSpeed.N}`]: 'Nhanh',
  [`${VoiceSpeed.C}`]: 'Chậm',
  [`${VoiceSpeed.RC}`]: 'Rất chậm',
};
