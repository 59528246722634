import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getUserInfo,
  getUsers,
  updateUserInfo,
  updateUserInfoWithAES,
} from '../@api';

export const aesKey = 'P9KF9sG1MvvfCKnt';

export default function useUser() {
  const { data, isLoading, isError } = useQuery('userInfo', getUserInfo, {
    refetchOnWindowFocus: false,
  });
  return { userInfo: data?.data, isLoading, isError };
}

export function useUserList() {
  const { data, isLoading, isError } = useQuery('getUsers', getUsers, {
    refetchOnWindowFocus: false,
  });
  return { users: data?.data, isLoading, isError };
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  return useMutation(updateUserInfo, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUsers');
    },
  });
}

export function useUpdateUserWithAES() {
  const queryClient = useQueryClient();
  return useMutation(updateUserInfoWithAES, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUsers');
    },
  });
}
