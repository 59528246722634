import React from 'react';
import { Dropdown, Menu } from 'antd';
import Logout from 'containers/Authen/Logout';
import UpdatePassword from './UpdatePassword';
import Avatar from 'antd/lib/avatar/avatar';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { KeyOutlined, LogoutOutlined } from '@ant-design/icons';
import useUser from '../hooks/useUser';

const UserControl = () => {
  const { userInfo } = useUser();

  const UserDropdown = (
    <Menu>
      <MenuItem key="1">
        <KeyOutlined />
        <UpdatePassword />
      </MenuItem>
      <MenuItem key="2">
        <LogoutOutlined />
        <Logout />
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={UserDropdown}
        key="user-control"
        placement="topRight"
        overlayStyle={{
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Container>
          <Avatar
            style={{ backgroundColor: '#87d068', marginRight: '14px' }}
            icon={<UserOutlined />}
          />
          <span>{userInfo?.fullName}</span>
        </Container>
      </Dropdown>
    </>
  );
};

const Container = styled.div`
  cursor: pointer;
`;

const MenuItem = styled(Menu.Item)`
  > span {
    display: flex;
    align-items: center;

    > span {
      margin-right: 10px;
    }
  }
`;

export default UserControl;
