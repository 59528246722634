import dayjs from 'dayjs';

export const dateParser = (dateString: string): Date | null => {
  const reg = new RegExp(/\\?\/Date\((.*)\)\\?\//i);
  if (!reg.test(dateString)) return null;

  const date = reg.exec(dateString);
  return date ? new Date(parseFloat(date?.[1])) : null;
};

export const getDate = (dateString: string): Date | null => {
  if (!dateString) return null;
  return new Date(dateString);
};

export function dateTimeFormat(value: any, format?: string) {
  if (!value) return '--';
  return dayjs(value).format(format || 'DD/MM/YYYY, HH:mm');
}

export function getTimeDuration(start: number, end: number) {
  let diffSecond = end - start;
  let hours = Math.floor(diffSecond / 3600);
  diffSecond %= 3600;
  let minutes = Math.floor(diffSecond / 60);
  let seconds = diffSecond % 60;
  return `${hours}giờ ${minutes}phút ${seconds}giây`;
}

export function secondsToHms(sec: number) {
  const hours = Math.floor(sec / 3600); // get hours
  const minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  const seconds = sec - hours * 3600 - minutes * 60; //  get seconds

  // add 0 if value < 10; Example: 2 => 02
  const cHours = hours === 0 ? '' : hours < 10 ? '0' + hours : hours;
  const cMinutes = minutes === 0 ? '' : minutes < 10 ? '0' + minutes : minutes;
  const cSeconds = seconds === 0 ? '' : seconds < 10 ? '0' + seconds : seconds;

  return (
    (cHours ? `${cHours}h` : '') +
    (cMinutes ? ` ${cMinutes}'` : '') +
    (cSeconds ? ` ${cSeconds}s` : '')
  ); // Return is 0h 0m 0s
}
