import { messaging } from './';

export default async function getToken(token: string) {
  let pairToken = '';
  register();

  await messaging
    ?.getToken({ vapidKey: token })
    .then((currentToken: any) => {
      if (currentToken) {
        pairToken = currentToken;
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.'
        );
        // ...
      }
    })
    .catch((err: any) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });

  return pairToken;
}

export function register() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('./firebase-messaging-sw.js')
      .catch(function (error) {
        // Failed registration, service worker won’t be installed
        console.log('Service worker registration failed, error:', error);
      });
  }
}
