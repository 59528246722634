import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import LoginForm from './LoginForm';
import { LoginFormValues } from '@types';
import { encrypt } from 'utils';
import { LoginRsa } from '@api';
import { useAuth } from 'context/AuthContext';
import radiobg from '../../images/radiobg.png';
import Logo from '../../components/Logo';
import { Space, Modal } from 'antd';
import { getCompanyInfo } from '@api/company';
import { saveMyPassword } from 'utils/passwordUtil';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export type CompanyInfoType = {
  appName: string;
  companyName: string;
  address: string;
  hotline: string;
  logoUrl: string;
  app: string;
  customer: {
    name: string;
    shortName: string;
  };
  gcsk: string;
};

const { warn } = Modal;

const Login = () => {
  const { setAuth } = useAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfoType | null>(null);
  const mutation = useMutation<any, any, any, any>(LoginRsa, {
    onSuccess: (data) => {
      if (data?.data?.sessionKey) {
        setAuth(true);
        localStorage.setItem('sessionKey', data?.data?.sessionKey);
      }
      if (data?.data?.messageError) {
        warn({
          content: <div>{data?.data?.messageError}</div>,
        });
      }
    },
  });

  async function handleLogin(values: LoginFormValues) {
    if (companyInfo?.gcsk && !executeRecaptcha) {
      return;
    }

    if (companyInfo?.gcsk && executeRecaptcha) {
      const result = await executeRecaptcha('loginAction');
      if (!result) return;
    }

    const encryptedPassword = encrypt(values.password);
    mutation.mutate({
      params: { userName: values.userName, password: encryptedPassword },
    });
    saveMyPassword(encryptedPassword);
  }

  useEffect(() => {
    getCompanyInfo()
      .then((response) => {
        setCompanyInfo(response.data);
      })
      .catch(() => {
        setCompanyInfo(null);
      });
  }, []);

  return (
    <StyledWrapper>
      <StyledImg>
        <div className="img-wrapper">
          <div className="pt-1">
            <Logo height={70} text={companyInfo?.appName} />
          </div>
          <img src={radiobg} className="object-contain" alt="radio-login-bg" />
        </div>
      </StyledImg>
      <StyledFormWrapper className="pr-5">
        <StyledGrid>
          <div
            className="pt-3 pb-3 pl-3 font-bold"
            dangerouslySetInnerHTML={{
              __html: companyInfo?.customer?.name || '',
            }}
          />
          <LoginForm
            onSubmit={handleLogin}
            isLoading={mutation.isLoading}
            loginError={
              mutation.isError
                ? mutation?.error?.response?.data?.messageError ||
                  'Không đăng nhập được hệ thống, vui lòng kiểm tra lại'
                : ''
            }
          />
          <Space size={24} className="pt-3">
            {companyInfo?.logoUrl && (
              <img
                src={companyInfo?.logoUrl}
                alt="logo"
                style={{ maxWidth: 260 }}
              />
            )}
            <div className="flex-col">
              <span>{companyInfo?.app || ''}</span>
              <span className="strong" style={{ textTransform: 'uppercase' }}>
                {companyInfo?.companyName || ''}
              </span>
              <span className="strong">{companyInfo?.address || ''}</span>
              {companyInfo?.hotline && (
                <div>
                  Điện thoại:{' '}
                  <span className="strong">{companyInfo.hotline}</span>
                </div>
              )}
            </div>
          </Space>
        </StyledGrid>
      </StyledFormWrapper>
    </StyledWrapper>
  );
};

const StyledFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-row-gap: 20px;
`;

const StyledImg = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  .img-wrapper {
    width: 480px;
  }
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default Login;
