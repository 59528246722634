import { AddressInfo } from './device';

export type RecordingType = {
  masterIMEI: string; // IMEI của thiết bị phát
  location: AddressInfo; // Địa chỉ của thiết bị
  info: string; // Thông tin thiết bị
  createTime: number; // Thời gian cập nhật
  createBy: string; // Người cập nhật
  enable: boolean; // Cho phép hay không
};

export type ScheduleType = {
  id: string; // ID lịch phát
  idString: string;
  fileName: string; // Tên file
  playAt: number; // Phát lúc
  repeate: number; // Lặp lại, 0: NONE, 1: EVERYDAY, 2: EVERYWEEK.
  repeateEveryWeek: number[]; // Ngày lặp lại
  description: string; // Mô tả
  content: string; // lấy nội dung lịch phát ( TTS)
  title: string; // Tiêu dề mô tả lịch phát
  allow: boolean; // trạng thái phê duyêt
  modifyByUser: string; // Người chỉnh sửa
  modifyTime: number; // Thời gian chỉnh sửa
  enable: boolean; // Cho phép kích hoạt hay không
  masterIMEI: string; // IMEI bộ phát
  status: string; // Trạng thái phát
  duration: number; // Tổng thời gian phát
  lastPlay: number; // Thời gian phát lần cuối.
  playAddress: string; // Địa điểm phát.
  url?: string; // file ghi âm
};

export type UpdateSchedulesType = {
  modifyByUser?: string;
  timeChange?: string;
  listScheduleId: string[];
};

export enum RepeateScheduleType {
  'NONE' = 0,
  'EVERYDAY' = 1,
  'EVERY_MON' = 2,
  'EVERY_TUE' = 3,
  'EVERY_WED' = 4,
  'EVERY_THU' = 5,
  'EVERY_FRI' = 6,
  'EVERY_SET' = 7,
  'EVERY_SUN' = 8,
}

export const RepeateScheduleLabel = {
  [`${RepeateScheduleType['NONE']}`]: '',
  [`${RepeateScheduleType['EVERYDAY']}`]: 'Hàng ngày',
  [`${RepeateScheduleType['EVERY_MON']}`]: 'T2',
  [`${RepeateScheduleType['EVERY_TUE']}`]: 'T3',
  [`${RepeateScheduleType['EVERY_WED']}`]: 'T4',
  [`${RepeateScheduleType['EVERY_THU']}`]: 'T5',
  [`${RepeateScheduleType['EVERY_FRI']}`]: 'T6',
  [`${RepeateScheduleType['EVERY_SET']}`]: 'T7',
  [`${RepeateScheduleType['EVERY_SUN']}`]: 'CN',
};

export const RepeatScheduleDay = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];
