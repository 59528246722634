import { User } from '@types';
import useUser from 'hooks/useUser';
import React, { FC } from 'react';

type UserType = {
  user?: User;
};

const UserContext = React.createContext<{
  user?: User;
}>({
  user: undefined,
});

const UserProvider: FC<UserType> = ({ children }) => {
  const { userInfo, isLoading: isUserInfoLoading } = useUser();

  return (
    <UserContext.Provider
      value={{
        user: userInfo,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserInfo = () => React.useContext(UserContext);

export { UserProvider, useUserInfo };
