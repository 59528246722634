import React from 'react';
import { useAuth } from 'context/AuthContext';
import { useMutation } from 'react-query';
import { logout } from '@api';
import { messaging } from '../../firebase';

const Logout = () => {
  const { setAuth } = useAuth();
  const mutation = useMutation(logout, {
    onSuccess: () => {
      setAuth(false);
      localStorage.removeItem('sessionKey');
    },
  });

  const handleLogout = async () => {
    // try {
    //   const token = await messaging.getToken();
    //   mutation.mutate(token);
    // } catch (e) {
    //   mutation.mutate('');
    // }

    setAuth(false);
    localStorage.removeItem('sessionKey');
  };

  return <div onClick={handleLogout}>Đăng xuất</div>;
};

export default Logout;
