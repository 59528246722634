import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from '../components/Layout';
import { DeviceProvider } from 'context/DeviceContext';
import { UserProvider } from './UserProvider';

const DashboardAsync = React.lazy(() => import('./Dashboard'));
const DeviceAsync = React.lazy(() => import('./Device'));
const GroupAsync = React.lazy(() => import('./Group'));
const UserAsync = React.lazy(() => import('./User'));
const ReportHistoryAsync = React.lazy(() => import('./Report/ReportHistory'));
const ReportResetAsync = React.lazy(() => import('./Report/ReportReset'));
const SoundContentAsync = React.lazy(() => import('./Sound'));
const RecordAsync = React.lazy(() => import('./Record'));
const ContentManagerAsync = React.lazy(() => import('./ContentManager'));

const AuthenticatedPage = () => {
  return (
    <UserProvider>
      <DeviceProvider>
        <BrowserRouter>
          <Switch>
            <Layout>
              <Suspense
                fallback={
                  <Spin
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: 'calc(50% + 100px)',
                    }}
                  />
                }
              >
                <Route path="/" exact>
                  <DashboardAsync />
                </Route>
                <Route path="/thiet-bi" exact>
                  <DeviceAsync />
                </Route>
                <Route path="/nhom" exact>
                  <GroupAsync />
                </Route>
                <Route path="/nguoi-dung" exact>
                  <UserAsync />
                </Route>
                <Route path="/bao-cao-phat-thanh" exact>
                  <ReportHistoryAsync />
                </Route>
                <Route path="/bao-cao-reset" exact>
                  <ReportResetAsync />
                </Route>
                <Route path="/chuyen-doi-am-thanh" exact>
                  <SoundContentAsync />
                </Route>
                <Route path="/ghi-am" exact>
                  <RecordAsync />
                </Route>
                <Route path="/noi-dung">
                  <ContentManagerAsync />
                </Route>
              </Suspense>
            </Layout>
          </Switch>
        </BrowserRouter>
      </DeviceProvider>
    </UserProvider>
  );
};

export default AuthenticatedPage;
