import React, { FC, useState } from 'react';
import { Form, Input, Button, message, Checkbox } from 'antd';
import { encrypt } from 'utils';
import useUser, { useUpdateUserWithAES } from 'hooks/useUser';
import styled from 'styled-components';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import {
  compareMyPassword,
  passwordStrength,
  saveMyPassword,
} from 'utils/passwordUtil';
import { cryptoEncrypt } from 'utils/cryptoEncrypt';

type Props = {
  onSuccess: () => void;
};

const FormUpdatePassword: FC<Props> = ({ onSuccess }) => {
  const { userInfo } = useUser();
  const [form] = Form.useForm();
  const updateUser = useUpdateUserWithAES();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = (values: any) => {
    if (userInfo) {
      const encryptedPassword = encrypt(values.newPassword);
      const userModel = {
        ...Object.assign(userInfo),
        password: encryptedPassword,
      };

      const sessionKey = localStorage.getItem('sessionKey') || '';
      const encryptData = cryptoEncrypt(JSON.stringify(userModel));

      updateUser.mutateAsync({ userAES: encryptData, sessionKey }).then(() => {
        message.success('Cập nhật mật khẩu thành công');
        saveMyPassword(encryptedPassword);
        onSuccess();
      });
    }
  };

  const showAllPassword = (e: any) => {
    const checked = e.target.checked;
    setShowPassword(checked);
    setShowNewPassword(checked);
    setShowConfirmPassword(checked);
  };

  return (
    <FormContent>
      <Form
        name="update-password"
        layout="vertical"
        initialValues={{ oldPassword: '', newPassword: '', reNewPassword: '' }}
        onFinish={onSubmit}
        form={form}
      >
        <ShowPassCheckbox>
          Hiện mật khẩu{' '}
          <Checkbox value={showPassword} onChange={(e) => showAllPassword(e)} />
        </ShowPassCheckbox>
        <FormItem>
          <Form.Item
            label="Mật khẩu cũ"
            name="oldPassword"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: 'Xin hãy nhập mật khẩu cũ!',
              },
              () => ({
                validator: (_, value) =>
                  compareMyPassword(encrypt(value || ''))
                    ? Promise.resolve()
                    : Promise.reject(new Error('Mật khẩu cũ không khớp!')),
              }),
            ]}
          >
            <Input type={showPassword ? 'text' : 'password'} />
          </Form.Item>
          <EyeIcon onClick={() => setShowPassword(!showPassword)}>
            {showPassword && <EyeOutlined />}
            {!showPassword && <EyeInvisibleOutlined />}
          </EyeIcon>
        </FormItem>

        <FormItem>
          <Form.Item
            label="Mật khẩu mới"
            name="newPassword"
            labelAlign="left"
            rules={[
              { required: true, message: 'Xin hãy nhập mật khẩu mới!' },
              () => ({
                validator: (_, value) => passwordStrength(value),
              }),
            ]}
          >
            <Input type={showNewPassword ? 'text' : 'password'} />
          </Form.Item>
          <EyeIcon onClick={() => setShowNewPassword(!showNewPassword)}>
            {showNewPassword && <EyeOutlined />}
            {!showNewPassword && <EyeInvisibleOutlined />}
          </EyeIcon>
        </FormItem>

        <FormItem>
          <Form.Item
            label="Xác nhận mật khẩu"
            name="reNewPassword"
            labelAlign="left"
            rules={[
              { required: true, message: 'Xin hãy nhập lại mật khẩu mới!' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'Mật khẩu mới không khớp. Vui lòng kiểm tra lại!'
                  );
                },
              }),
            ]}
          >
            <Input type={showConfirmPassword ? 'text' : 'password'} />
          </Form.Item>
          <EyeIcon onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
            {showConfirmPassword && <EyeOutlined />}
            {!showConfirmPassword && <EyeInvisibleOutlined />}
          </EyeIcon>
        </FormItem>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Cập nhật
          </Button>
        </Form.Item>
      </Form>
    </FormContent>
  );
};

const FormContent = styled.div`
  position: relative;
`;

const ShowPassCheckbox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const FormItem = styled.div`
  position: relative;
`;

const EyeIcon = styled.div`
  position: absolute;
  top: 34px;
  right: 12px;
  cursor: pointer;
`;

export default FormUpdatePassword;
