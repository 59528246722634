import { axiosInstance } from './axios';
import { AxiosPromise } from 'axios';
import { TTSType, TTSUpdateFormType, TTSCreateFormType } from '../@types';

export function getTTSInfo(): AxiosPromise<TTSType[]> {
  return axiosInstance.get('/tts/GetTtsInfo');
}

export function updateTTS(tts: TTSUpdateFormType) {
  return axiosInstance.post('/tts/UpdateTts', tts);
}

export function createTTS(tts: TTSCreateFormType) {
  return axiosInstance.post('/tts/CreateNewTts', tts);
}
