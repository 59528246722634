import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getDevice,
  updateDevice,
  getDevicePlayback,
  updateRecording,
  getDeviceByGroup,
} from '../@api';

export default function useDeviceStatus(enable?: boolean) {
  const { data, isLoading, isError } = useQuery('getDevice', getDevice, {
    enabled: enable ?? true,
    refetchOnWindowFocus: false,
  });
  return { data: data?.data, isLoading, isError };
}

export function useDeviceByGroup(groupId?: string) {
  const { data, isLoading, isError } = useQuery(
    'getDeviceByGroup',
    () => getDeviceByGroup(groupId),
    { enabled: !!groupId, refetchOnWindowFocus: false }
  );
  return { data: data?.data, isLoading, isError };
}

export function useUpdateDevice() {
  const client = useQueryClient();
  return useMutation(updateDevice, {
    onSuccess: () => {
      client.invalidateQueries(`getDevice`);
    },
  });
}

export function useUpdateDeviceRecording() {
  return useMutation(updateRecording);
}

export function useDevicePlayback() {
  return useMutation(getDevicePlayback);
}
