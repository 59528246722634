import { axiosInstance } from './axios';
import { AxiosPromise } from 'axios';
import { RecordingType, ScheduleType, UpdateSchedulesType } from '../@types';

export function getRecording(): AxiosPromise<RecordingType[]> {
  return axiosInstance.get('/recording');
}

export function getSchedule(): AxiosPromise<ScheduleType[]> {
  return axiosInstance.get('/schedule');
}

export function getScheduleById(
  id: number | string
): AxiosPromise<ScheduleType> {
  return axiosInstance.get(`/GetScheduleById/${id}`);
}

export function getScheduleByRepeate(): AxiosPromise<ScheduleType[]> {
  return axiosInstance.get(`/GetAllScheduleByRepeate`);
}

export function getAllScheduleByTime({
  fromTime,
  toTime,
}: {
  fromTime: string | number;
  toTime: string | number;
}): AxiosPromise<ScheduleType[]> {
  return axiosInstance.get(
    `/report/GetAllScheduleByTime/${fromTime}/${toTime}`
  );
}

export function getAllowScheduleByTime({
  fromTime,
  toTime,
}: {
  fromTime: string | number;
  toTime: string | number;
}): AxiosPromise<ScheduleType[]> {
  return axiosInstance.get(
    `/report/GetAllowSchedulesByTime/${fromTime}/${toTime}`
  );
}

export function allowSchedules(
  schedules: UpdateSchedulesType
): AxiosPromise<any> {
  return axiosInstance.post(`/schedule/allowSchedules`, schedules);
}
