import { Device } from '@types';
import { DEVICE_STATUS, STATUS_COLOR } from 'constant';
import { getDate } from './date';

export const ONLINE_TIME = 3 * 60 * 1000;

export const IsOnlineDevice = (device: Device) => {
  const date = getDate(device.lastUpdate);
  return date ? new Date().getTime() - date.getTime() <= ONLINE_TIME : false;
};

export const IsOfflineDevice = (device: Device) => {
  const date = getDate(device.lastUpdate);
  return date ? new Date().getTime() - date.getTime() > ONLINE_TIME : false;
};

export const GetDeviceStatus = (device: Device): DEVICE_STATUS => {
  if (IsOfflineDevice(device)) return DEVICE_STATUS.LOST_CONNECT;

  switch (device.runningMode) {
    case 'FM':
    case 'MIC':
      return DEVICE_STATUS.ONLINE;
    case 'NONE':
      return DEVICE_STATUS.OFFLINE;
    default:
      if (device.streamState === 'RUNNING') return DEVICE_STATUS.ONLINE;
      else return DEVICE_STATUS.WAITING;
  }
};

export const GetDeviceStatusColor = (device: Device) => {
  switch (GetDeviceStatus(device)) {
    case DEVICE_STATUS.ONLINE:
      return STATUS_COLOR.ON_COLOR;
    case DEVICE_STATUS.OFFLINE:
      return STATUS_COLOR.OFF_COLOR;
    case DEVICE_STATUS.WAITING:
      return STATUS_COLOR.WAIT_COLOR;
    case DEVICE_STATUS.LOST_CONNECT:
      return STATUS_COLOR.LOST_CONNECT;
    default:
      return STATUS_COLOR.OFF_COLOR;
  }
};
