import React, { Dispatch, FC, useState } from 'react';

type ContextType = {
  isAuthenticated: boolean;
  setAuth: Dispatch<boolean>;
};
const AuthContext = React.createContext<ContextType>({
  isAuthenticated: false,
  setAuth: () => {},
});

type Props = {
  children: React.ReactNode;
};
const AuthProvider: FC<Props> = ({ children }) => {
  const [auth, setAuth] = useState(!!localStorage.getItem('sessionKey'));

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: auth,
        setAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
