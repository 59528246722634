import { axiosInstance } from './axios';
import { AxiosPromise } from 'axios';
import {
  Device,
  DeviceControl,
  DevicePlaybackType,
  DeviceRecordingFormType,
} from '../@types';

export function getDevice(): AxiosPromise<Device[]> {
  return axiosInstance.get('/device/getDevices');
}

export function getDeviceByGroup(group?: string): AxiosPromise<Device[]> {
  return axiosInstance.get(`/device/getDevicesByGroup/${group}`);
}

export function getDeviceMaster(): AxiosPromise<Device[]> {
  return axiosInstance.get(`/device/getDevicesMaster`);
}

export function updateDevice(data: any) {
  return axiosInstance.post('/device/update', data);
}

export function updateDeviceControl(data: DeviceControl) {
  return axiosInstance.post('/device/control', data);
}

export function getDevicePlayback({
  imei,
  fromTime,
  toTime,
}: {
  imei: string | number;
  fromTime: string | number;
  toTime: string | number;
}): AxiosPromise<DevicePlaybackType[]> {
  return axiosInstance.get(`/playback/${imei}/${fromTime}/${toTime}`);
}

export function getDevicePlaybackByTime({
  fromTime,
  toTime,
}: {
  fromTime: string | number;
  toTime: string | number;
}): AxiosPromise<DevicePlaybackType[]> {
  return axiosInstance.get(`/playbackByTime/${fromTime}/${toTime}`);
}

export function updateRecording(data: DeviceRecordingFormType) {
  return axiosInstance.post('/recording', data);
}

export function getStreamUrlById(id: number | string): AxiosPromise<string> {
  return axiosInstance.get(`/device/streamUrl/${id}`);
}
