import axios from 'axios';
import { pushUserOut } from '../utils/authenUtils';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
});

axiosInstance.interceptors.request.use(
  function (config) {
    if (
      config.url?.includes('/user/login') &&
      !!process.env.REACT_APP_LOGIN_API_KEY
    ) {
      return {
        ...config,
        headers: {
          ...config.headers,
          'API-KEY': process.env.REACT_APP_LOGIN_API_KEY,
        },
      };
    }
    if (Boolean(localStorage.getItem('sessionKey'))) {
      return {
        ...config,
        headers: {
          ...config.headers,
          'API-KEY': localStorage.getItem('sessionKey'),
        },
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error.response?.status === 304 ||
      error.response?.status === 401 ||
      error.response?.status === 403
    ) {
      pushUserOut();
      return;
    }
    return Promise.reject(error);
  }
);
