import { encrypt } from './aesUtil';

export const saveMyPassword = (encryptedPassword: string) => {
  const reEncryptedPassword = encrypt(encryptedPassword);

  localStorage.setItem('user_key', reEncryptedPassword);
};

export const getMyPassword = () => {
  return localStorage.getItem('user_key');
};

export const compareMyPassword = (encryptedPassword: string) => {
  const reEncryptedPassword = encrypt(encryptedPassword);
  const savedPassword = getMyPassword();

  return savedPassword === reEncryptedPassword;
};

const Minimum_Length = 7;

export const passwordStrength = (value: string) => {
  if (value.length < Minimum_Length)
    return Promise.reject(
      new Error(`Mật khẩu tối thiểu ${Minimum_Length} ký tự!`)
    );

  if (!/[A-Z]+/g.test(value))
    return Promise.reject(new Error(`Mật khẩu phải có ít nhất 1 chữ in hoa!`));
  if (!/[a-z]+/g.test(value))
    return Promise.reject(new Error(`Mật khẩu phải có ít nhất 1 chữ thường!`));
  if (!/[a-zA-Z]+/g.test(value))
    return Promise.reject(new Error(`Mật khẩu phải có ít nhất 1 chữ cái!`));
  if (!/[0-9]+/g.test(value))
    return Promise.reject(new Error(`Mật khẩu phải có ít nhất 1 chữ số!`));
  if (!/[^0-9a-zA-Z\._]+/g.test(value))
    return Promise.reject(
      new Error(`Mật khẩu phải có ít nhất 1 ký tự đặc biệt!`)
    );

  return Promise.resolve();
};
