import React, { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import FormUpdatePassword from './Form';

const UpdatePassword = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div onClick={() => setOpen(true)}>Đổi mật khẩu</div>
      <Modal
        title="Thay đổi mật khẩu"
        visible={open}
        onCancel={() => setOpen(false)}
        footer={false}
      >
        <FormUpdatePassword onSuccess={() => setOpen(false)} />
      </Modal>
    </div>
  );
};

export default UpdatePassword;
