import { aesKey } from 'hooks/useUser';
import * as CryptoJS from 'crypto-js';

export const cryptoEncrypt = (content: string) => {
  const encryptData = CryptoJS.AES.encrypt(
    content,
    CryptoJS.enc.Utf8.parse(aesKey),
    {
      mode: CryptoJS.mode.ECB,
    }
  ).toString();

  return encryptData;
};
