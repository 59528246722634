import React, { FC } from 'react';
import { Form, Input, Button, Alert, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { LoginFormValues } from '@types';

type Props = {
  onSubmit: (values: LoginFormValues) => void;
  isLoading?: boolean;
  loginError?: string;
};
const LoginForm: FC<Props> = ({ onSubmit, isLoading, loginError }) => {
  return (
    <Form
      layout="vertical"
      name="normal_login"
      className="login-form"
      initialValues={{ userName: '', password: '' }}
      onFinish={onSubmit}
    >
      <Form.Item
        name="userName"
        label="Đăng nhập vào tài khoản của bạn"
        rules={[{ required: true, message: 'Chưa nhập tên đăng nhập' }]}
      >
        <Input
          size="large"
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Chưa nhập mật khẩu' }]}
      >
        <Input
          size="large"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item>
        <Space size="large">
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Đăng nhập
          </Button>
        </Space>
      </Form.Item>
      {loginError && <Alert type="error" message={loginError} />}
    </Form>
  );
};

export default LoginForm;
