export type User = {
  email: string;
  enable?: boolean;
  fullName?: string;
  groupDeviceManager?: string[];
  info?: string;
  locationControl: string[];
  password?: string;
  permissions?: number[];
  phoneNumber?: string;
  userName: string;
  createdDate?: number;
  lastLoginTime?: number;
  userManagement?: string[];
};

export type LoginFormValues = {
  userName: string;
  password: string;
};

export enum Permission {
  EDIT_USER = 1,
  MANAGER_GROUP = 2,
  CONTROL_DEVICE = 4,
  END_USER = 8,
  TECHNICIAL_USER = 16,
  PLAYBACK_PERMISSON = 32,
  // OFFLINE_ONLY_PERMISSION = 64,
  TTS_PERMISSION = 128,
  REPORT_PERMISSION = 256,
  FM_SCHEDULE_PERMISSION = 512,
  ALLOW_SCHEDULE_PERMISSION = 4096,
  ADMIN = 0xffff,
}

export const PermissionName = {
  [`${Permission.EDIT_USER}`]: 'Quản lý người dùng',
  [`${Permission.MANAGER_GROUP}`]: 'Cho phép quản lý nhóm',
  [`${Permission.CONTROL_DEVICE}`]: 'Cho phép điều khiển thiết bị',
  [`${Permission.END_USER}`]: 'Người dùng cuối',
  [`${Permission.TECHNICIAL_USER}`]: 'Cho phép cấu hình kỹ thuật ',
  [`${Permission.PLAYBACK_PERMISSON}`]: 'Cho phép nghe lại audio',
  // [`${Permission.OFFLINE_ONLY_PERMISSION}`]: 'Chỉ dùng offline',
  [`${Permission.TTS_PERMISSION}`]: 'Cho phép sử dụng TTS',
  [`${Permission.REPORT_PERMISSION}`]: 'Cho phép xem báo cáo',
  [`${Permission.FM_SCHEDULE_PERMISSION}`]: 'Cho phép cài đặt tiếp sóng FM',
  [`${Permission.ALLOW_SCHEDULE_PERMISSION}`]: 'Cho phép duyệt nội dung',
  // [`${Permission.ADMIN}`]: 'Administrator',
};
