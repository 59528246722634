import React, { useEffect, useState } from 'react';
import LoginPage from './LoginPage';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { getCompanyInfo } from '@api/company';
import { CompanyInfoType } from 'containers/Authen/Login';

const SITE_KEY = process.env.REACT_APP_GG_CAPTCHA_SITE_KEY;

const UnauthenticatedPage = () => {
  const [companyInfo, setCompanyInfo] = useState<CompanyInfoType | null>(null);

  useEffect(() => {
    getCompanyInfo()
      .then((response) => {
        setCompanyInfo(response.data);
      })
      .catch(() => {
        setCompanyInfo(null);
      });
  }, []);

  return companyInfo?.gcsk ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={companyInfo?.gcsk}
      language="vi"
      // useRecaptchaNet={true}
      // useEnterprise={true}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <LoginPage />
    </GoogleReCaptchaProvider>
  ) : (
    <LoginPage />
  );
};

export default UnauthenticatedPage;
