import React from 'react';
import { Popover, List } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useDevice } from 'context/DeviceContext';
import { dateTimeFormat } from 'utils';

const TopNotification = () => {
  const { notify } = useDevice();

  const content = () => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={notify}
        style={{ width: '350px', maxHeight: '500px', overflowY: 'auto' }}
        renderItem={(item) => (
          <List.Item>
            <Cotent>
              <Title>{item?.notification?.title || item?.header}</Title>
              <Desc>{item?.notification?.body || item.content}</Desc>
              {item?.time && (
                <Time>{dateTimeFormat(item?.time, 'HH:mm - DD.MM.YYYY')}</Time>
              )}
            </Cotent>
          </List.Item>
        )}
      />
    );
  };

  return (
    <NotifyIcon>
      <Popover content={content()} trigger={['click']} placement="bottomRight">
        <div>
          {notify?.length > 0 && (
            <Total>{notify.length > 99 ? '+99' : notify.length}</Total>
          )}
          <BellOutlined />
        </div>
      </Popover>
    </NotifyIcon>
  );
};

const NotifyIcon = styled.div`
  padding: 12px 30px 11px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 24px;
  transition: 0.3s;
  position: relative;

  &:hover {
    color: #1890ff;
  }
`;

const Total = styled.div`
  background: #fe9c9c;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 40px;
  top: 12px;
`;

const Cotent = styled.div`
  width: 100%;
  padding-right: 4px;
`;
const Title = styled.div`
  font-weight: bold;
`;
const Desc = styled.div`
  color: #a9a9a9;
`;
const Time = styled.div`
  text-align: right;
  font-size: 11px;
  color: #a9a9a9;
`;

export default TopNotification;
