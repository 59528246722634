import { Device, Notify } from '@types';
import useDeviceStatus, { useDeviceByGroup } from 'hooks/useDeviceStatus';
import React, { FC, useEffect, useState } from 'react';
import getToken from 'firebase/getToken';
import { checkSupportedNotifyBrowser } from '../firebase/util';
import useMessaging from 'hooks/useMessaging';
import useFirebaseToken from 'hooks/useFirebaseToken';
import { useNotity } from 'hooks/useNotify';
import useUser from 'hooks/useUser';
import { useQuery } from 'react-query';
import { getDeviceMaster } from '@api';

const token = process.env.REACT_APP_FIREBASE_VAPIDKEY || '';

const DeviceContext = React.createContext<{
  deviceMaster: Device[];
  isDeviceMasterLoading: boolean;
  devices: Device[];
  isDeviceLoading: boolean;
  setDevices: (devices: Device[]) => void;
  deviceByLocation?: Device[];
  setDeviceByLocation?: (devices?: Device[]) => void;
  notify: Notify[];
}>({
  deviceMaster: [],
  isDeviceMasterLoading: false,
  devices: [],
  isDeviceLoading: false,
  setDevices: () => {},
  deviceByLocation: [],
  setDeviceByLocation: () => {},
  notify: [],
});

type Props = {
  children: React.ReactNode;
};
const DeviceProvider: FC<Props> = ({ children }) => {
  const { userInfo } = useUser();
  const { data: notifications } = useNotity();
  const updateToken = useFirebaseToken();
  const firebaseMessaging = useMessaging();
  const { data } = useDeviceByGroup(userInfo?.groupDeviceManager?.[0]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [deviceByLocation, setDeviceByLocation] = useState<
    Device[] | undefined
  >(undefined);
  const [notify, setNotify] = useState<Array<Notify>>([]);

  const [requestAll, setRequestAll] = useState(false);
  const { data: devicesList, isLoading: isDeviceLoading } =
    useDeviceStatus(requestAll);

  const { data: deviceMaster, isLoading: isDeviceMasterLoading } = useQuery(
    'getDeviceMaster',
    getDeviceMaster,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setDevices(devicesList || []);
  }, [devicesList]);

  useEffect(() => {
    setDevices(devicesList || deviceMaster?.data || []);
  }, [devicesList, deviceMaster]);

  useEffect(() => {
    if (notifications) setNotify(notifications);
  }, [notifications]);

  useEffect(() => {
    checkSupportedNotifyBrowser();

    const timeout = setTimeout(() => {
      setRequestAll(true);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (token)
      getToken(token).then((newToken) => {
        if (newToken) updateToken.mutateAsync(newToken);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const notifylist = [...notify];
    if (firebaseMessaging) setNotify([...notifylist, firebaseMessaging]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseMessaging]);

  return (
    <DeviceContext.Provider
      value={{
        deviceMaster: deviceMaster?.data || [],
        isDeviceMasterLoading: isDeviceMasterLoading,
        devices: devices,
        isDeviceLoading: isDeviceLoading,
        setDevices: setDevices,
        deviceByLocation,
        setDeviceByLocation,
        notify,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};

const useDevice = () => React.useContext(DeviceContext);

export { DeviceProvider, useDevice };
